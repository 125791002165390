import React, { useEffect } from "react";
import styled from "styled-components";
import { useDarkMode } from "../ducks/colourModeSlice";
import { useUpdateHeaderActions } from "../ducks/headerActionsSlice";
import { loginFriend, logout } from "../ducks/loginSlice";
import { useSelector, useDispatch } from "react-redux";
import postToFunction from "../utils/postToFunction";
import {
  initialFormValues as allFormValues,
  FormField,
  Error,
  changeFormValue,
  submitForm,
  anyValueEmpty,
} from "../utils/formHelpers";
import { navigate } from "gatsby";
import ProgressMeter from "../components/progress-meter";
import { H3, H4, Body, Detail } from "../components/common/typography/";
import CTAButton from "../components/common/cta-button";
import SEO from "../components/common/seo";
import featherBackground from "../assets/images/FeatherBackground.png";
import bottleTop from "../assets/images/BottleTop.png";
import bottleFront from "../assets/images/BottleFront.png";
import bottleEmbossRight from "../assets/images/BottleEmbossRight.png";
import { totalFriendsRequired } from "../config";

const initialFormValues = allFormValues.filter(
  (field) => field.key !== "moment"
);

const JoinPage = (props) => {
  useDarkMode();
  useUpdateHeaderActions({
    left: {
      text: "See Whisky",
      to: "/",
    },
    right: {
      text: "Log Out",
      action: () => {
        dispatch(logout());
        navigate("/");
      },
    },
  });

  const dispatch = useDispatch();
  let { code, fname, friendCount } = useSelector((state) => state.login);
  if (!fname) fname = "Your Friend";
  const { "*": linkCode } = props;

  useEffect(() => {
    if (!code) {
      if (!linkCode) navigate("/");
      const getBallot = async () => {
        const response = await postToFunction("/.netlify/functions/getBallot", {
          code: linkCode,
        });
        if (response.error) {
          navigate("/");
        } else {
          dispatch(
            loginFriend({
              code: response.code,
              fname: response.fname,
              friendCount: response.friendCount,
            })
          );
        }
      };
      getBallot();
    }
  }, [code, linkCode, dispatch]);

  const [formIsSaving, setFormIsSaving] = React.useState(false);
  const [fetchError, setFetchError] = React.useState(null);
  const [formValues, setFormValues] = React.useState(initialFormValues);
  const [formSaveSuccess, setFormSaveSuccess] = React.useState(null);

  const onSubmit = () =>
    submitForm({
      functionUrl: "/.netlify/functions/addToBallot",
      extraData: { code },
      successCallback: (response) => {
        setFormSaveSuccess(response);
        dispatch(
          loginFriend({
            code,
            fname,
            friendCount: response.friendCount,
          })
        );
      },
      formValues,
      setFormValues,
      setFetchError,
      setFormIsSaving,
    });

  const friendsLeft = totalFriendsRequired - friendCount - 1;
  const friendsLeftForText = React.useRef();
  if (!formSaveSuccess) friendsLeftForText.current = friendsLeft;

  return (
    <Root>
      <SEO title={`Win with ${fname}`} />
      <Background>
        <Title visible={Boolean(fname)}>
          Win a 50yr old Glengoyne
          <br />
          with <i>{fname}</i>
        </Title>
        <Intro>
          {friendsLeftForText.current < 0 ? (
            <span>
              This ballot already has enough people to be in with a chance, but
              you can still enter
            </span>
          ) : (
            <span>
              Get{" "}
              <Highlight>
                {friendsLeftForText.current} more friend
                {friendsLeftForText.current === 1 ? "" : "s"}
              </Highlight>{" "}
              to sign up after you, and be in with a chance to win a piece of
              Glengoyne History. Enter
            </span>
          )}{" "}
          your details below to add to {fname}'s progress.
        </Intro>
        {formSaveSuccess ? (
          <Form>
            <H4>Thanks {formSaveSuccess.fname}</H4>
            <Body>
              You have been successfully added to {fname}'s entry. Good luck.
            </Body>
          </Form>
        ) : (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            {formValues.map((field) => (
              <FormField
                key={field.key}
                field={field}
                onChange={changeFormValue(
                  field.key,
                  setFormValues,
                  setFetchError
                )}
                narrow
              />
            ))}
            <Error content={fetchError}>{fetchError}</Error>
            <CTAButton
              inverted
              spaced
              fullWidth
              text={formIsSaving ? "Saving..." : "Enter Ballot"}
              action={onSubmit}
              disabled={anyValueEmpty(formValues) || formIsSaving}
            />
            <Agreement>
              By signing up with your email, you agree to receive updates about
              Glengoyne Highland Single Malt.
            </Agreement>
          </Form>
        )}
      </Background>
      <ProgressMeter block={true} friendCount={friendCount} fname={fname} />
      <Gallery>
        <HalfWidthLeft />
        <HalfWidthRight />
        <FullWidth />
      </Gallery>
    </Root>
  );
};

const Root = styled.div`
  padding-top: 146px;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Background = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url(${featherBackground});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    background: none;
  }
`;

const Title = styled(H3)`
  max-width: 600px;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 1s linear;
`;

const Intro = styled(Body)`
  max-width: 450px;
  margin-top: ${({ theme }) => theme.spacing.md};
  padding: 0 ${({ theme }) => theme.spacing.lg};
`;

const Highlight = styled.span`
  color: ${({ theme }) => theme.colour.whiskyOrange};
`;

const Form = styled.form`
  padding: ${({ theme }) => theme.spacing.xxl};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`;

const Agreement = styled(Detail)`
  max-width: 300px;
`;

const Gallery = styled.div`
  width: 90%;
  margin-top: ${({ theme }) => theme.spacing.xl};
  margin-bottom: ${({ theme }) => theme.spacing.mega};
  display: grid;
  grid-template-rows: 1.27fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spacing.sm};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-template-rows: 3fr 3fr 1fr;
    grid-template-columns: 1fr;
    margin-top: ${({ theme }) => theme.spacing.lg};
  }
`;

const HalfWidthLeft = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  background-image: url(${bottleTop});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
`;

const HalfWidthRight = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  background-image: url(${bottleFront});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`;

const FullWidth = styled.div`
  width: 100%;
  padding-bottom: 30%;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 2;
  background-image: url(${bottleEmbossRight});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
`;

export default JoinPage;
