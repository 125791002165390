import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import { Body } from "./common/typography/";
import { totalFriendsRequired } from "../config";
import TickIcon from "../assets/svg/TickIcon.svg";
import PadlockIcon from "../assets/svg/PadlockIcon.svg";

const ProgressMeter = ({
  block = false,
  friendCount = 0,
  fname = "Your Friend",
}) => {
  const notches = [...Array(totalFriendsRequired + 1).keys()];
  return (
    <Root block={block}>
      <Title block={block}>{fname}'s Progress</Title>
      <BarContainer>
        <Labels>
          {notches.map((num) => (
            <Label
              key={num}
              num={num}
              total={totalFriendsRequired}
              count={friendCount}
            >
              {num === 0
                ? "Signed Up"
                : `${num}${
                    num > friendCount ? ` friend${num === 1 ? "" : "s"}` : ""
                  }`}
            </Label>
          ))}
        </Labels>
        <Bar at={friendCount + 1} total={totalFriendsRequired}>
          {notches.map((num) => (
            <Notch
              key={num}
              num={num}
              total={totalFriendsRequired}
              at={friendCount}
            />
          ))}
        </Bar>
        <Icons>
          {notches.map((num) => (
            <Icon
              key={num}
              num={num}
              total={totalFriendsRequired}
              count={friendCount}
            >
              {num <= friendCount ? <TickIcon /> : <PadlockIcon />}
            </Icon>
          ))}
        </Icons>
      </BarContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding-bottom: ${({ theme }) => theme.spacing.mega};
  border-top: ${({ theme, block }) =>
    block
      ? `1px solid ${transparentize(0.9, theme.colour.fullWhite)}`
      : "none"};
  border-bottom: ${({ theme, block }) =>
    block
      ? `1px solid ${transparentize(0.9, theme.colour.fullWhite)}`
      : "none"};
  margin: ${({ theme, block }) =>
    `${block ? 0 : theme.spacing.xl} auto ${theme.spacing.xl} auto`};
  overflow: hidden;
`;

const Title = styled(Body)`
  display: ${({ block }) => (block ? "block" : "none")};
  margin: ${({ theme }) => theme.spacing.xxl} auto;
`;

const BarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    flex-direction: row;
    transform: translateX(23%);
  }
`;

const Bar = styled.div`
  position: relative;
  width: 80%;
  min-height: 16px;
  background-image: ${({ theme, at, total }) =>
    `linear-gradient(120deg, ${theme.colour.darkWhisky} ${
      20 * (at / total)
    }%, ${theme.colour.whiskyOrange} ${33 * (at / total)}%, ${
      theme.colour.darkWhisky
    } ${53 * (at / total)}%, ${theme.colour.lightBlack} ${
      100 * (at / total)
    }% )`};
  border-radius: ${({ theme }) => theme.spacing.tiny};
  margin: ${({ theme }) => `${theme.spacing.xl} 0 ${theme.spacing.sm} 0`};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 16px;
    height: 520px;
    background-image: ${({ theme, at, total }) =>
      `linear-gradient(170deg, ${theme.colour.darkWhisky} ${
        20 * (at / total)
      }%, ${theme.colour.whiskyOrange} ${33 * (at / total)}%, ${
        theme.colour.darkWhisky
      } ${53 * (at / total)}%, ${theme.colour.lightBlack} ${
        100 * (at / total)
      }% )`};
    order: -1;
  }
`;

const Notch = styled.div`
  width: 1px;
  height: ${({ num, at }) => (num <= at ? `8px` : `16px`)};
  background-color: ${({ theme }) => theme.colour.fullBlack};
  position: absolute;
  left: calc(${({ num, total }) => (num / total) * 92}% + 4%);
  top: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: ${({ num, at }) => (num <= at ? `8px` : `16px`)};
    height: 1px;
    left: auto;
    top: calc(${({ num, total }) => (num / total) * 92}% + 4%);
    right: 0;
  }
`;

const Labels = styled.div`
  position: relative;
  width: 80%;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 100px;
    height: 520px;
    margin-bottom: 32px;
    margin-left: ${({ theme }) => theme.spacing.lg};
  }
`;

const Label = styled(Body)`
  width: max-content;
  position: absolute;
  left: calc(${({ num, total }) => (num / total) * 92}% + 4%);
  transform: translateX(-50%);
  color: ${({ theme, num, count }) =>
    num > count ? theme.colour.fullWhite : theme.colour.whiskyOrange};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    left: auto;
    top: calc(${({ num, total }) => (num / total) * 92}% + 4%);
    transform: translateY(-50%);
  }
`;

const Icons = styled.div`
  position: relative;
  width: 80%;
  margin-top: ${({ theme }) => theme.spacing.xxs};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 30%;
    height: 520px;
    margin-top: 32px;
    transform: translateX(-100px);
  }
`;

const Icon = styled.div`
  position: absolute;
  left: calc(${({ num, total }) => (num / total) * 92}% + 4%);
  transform: translateX(-50%);
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    left: auto;
    top: calc(${({ num, total }) => (num / total) * 92}% + 4%);
    transform: translateY(-50%);
  }
`;

export default ProgressMeter;
